export async function fetchWithTimeout(resource, options) {
  const { timeout = 1000 } = options;

  const controller = new AbortController();
  const action = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(action);

  return response;
}

import Head from 'next/head';
import { Provider } from 'react-redux';
import { store } from 'src/redux/store';
import _ from 'lodash';

import 'src/assets/sass/style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-multi-carousel/lib/styles.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'react-loading-skeleton/dist/skeleton.css';

import PageError from 'pages/404';
import { GTM_ID, TRUE_HITS_URL } from 'src/constants';
import Script from 'next/script';
import { useEffect } from 'react';

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  if (pageProps.statusCode >= 400) {
    return (
        <PageError />
    );
  }
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <style jsx global>{`
        html,
        body {
          margin: 0px;
          padding: 0px;
          font-family: 'Mitr', sans-serif;
          letter-spacing: 0.2px;
        }
        p,
        h2,
        ul {
          margin-bottom: 0;
        }
        a {
          text-decoration: none;
          color: #000;
        }
        section {
          padding: 30px 0 0 0;
        }
      `}</style>
      <Provider store={store}>
        {/* -------------------------- Google Track Manager -------------------------- */}
        <Script
          id='gtag-base'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer', '${GTM_ID}');
                      `
          }}
        />
        <Component {...pageProps} />
        {/* -------------------------------- truehits -------------------------------- */}
        {<Script strategy='afterInteractive' type='text/javascript' src={`${TRUE_HITS_URL}`} />}
      </Provider>
    </>
  );
};

export default MyApp;

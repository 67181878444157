import { CLIENT_GSERVICE_URL } from 'src/constants';
import { fetchWithTimeout } from 'src/utils/fetch-with-timeout';

export const incrementCountview = async data => {
  let increase = 0;
  if ('pageviews' in data) {
    const params = {
      site: 'bangkokbiznews',
      newsid: data.id
    };
    try {
      const response = await fetchWithTimeout(`${CLIENT_GSERVICE_URL}/cpvs?site=${encodeURIComponent(params.site)}&newsid=${encodeURIComponent(params.newsid)}`, {
        timeout: 1000
      });
      const countview = await response.json();
      increase = countview + data.pageviews;
    } catch (error) {
      console.log('timeout :(');
    }
  }
  return increase;
};

import { useEffect, useState } from 'react';
import { API_URI, SHOW_COMMEMORATE } from 'src/constants';
import dynamic from 'next/dynamic';
import { setPWA } from 'src/utils/set-pwa';
import styled from 'styled-components';

const Header = dynamic(import('src/components/layouts/header'), { ssr: true });
const Footer = dynamic(import('src/components/layouts/footer'));

const Layout = ({ children, ads, sessionSevId, classLayout = '', bgImage = '' }) => {
  const [navData, setNavData] = useState(null);

  useEffect(async () => {
    const res = await fetch(`${API_URI}/api/v1.0/navigations/menu-nav`);
    const resJson = await res.json();
    if ('msg' in resJson) {
      setNavData(null);
    } else {
      setNavData(resJson);
    }
  }, []);

  return (
    <>
      <Header data={navData} sessionSevId={sessionSevId} />
      <PageWrapper bgImage={bgImage} className={`page-wrapper ${SHOW_COMMEMORATE === 'true' ? 'back-white' : ''} ${classLayout}`}>{children}</PageWrapper>
      <Footer data={navData} ads={ads} />
    </>
  );
};
const PageWrapper = styled.div`
  background-image: url(${props => props.bgImage});
  background-repeat: repeat;
  background-position: top center; 
`;

const Pwa = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  z-index: 300;
  top: 10px;
  @media (max-width: 480px) {
    top: 100px;
  }
  .class-pwa-popup {
    position: absolute;
    right: 10px;
    @media (max-width: 480px) {
      width: 288px;
      max-width: 100%;
      right: 50%;
      transform: translate(50%, 0);
    }
    .bg-pwa {
      display: flex;
      background-color: rgba(20, 20, 20, 0.93);
      padding: 8px 10px;
      color: #fff;
      align-items: center;
      border-radius: 6px;
      box-shadow: 0px 0px 13px 5px #0240876e;
      img {
        width: 50px;
        padding: 0 5px;
        vertical-align: middle;
        margin-right: 10px;
      }
      p {
        margin: 0;
        font-size: 14px;
        white-space: pre;
        padding: 5px;
        .kom-text {
          color: #0065d8;
        }
        &:nth-of-type(2) {
          padding: 0 5px 5px 5px;
          font-size: 12px;
        }
      }
      .add-button,
      .add-button-close {
        padding: 5px;
        text-align: center;
        white-space: pre;
        cursor: pointer;
      }

      .install-close {
        border-left: 1px solid #363636;
        padding-left: 10px;
        margin-left: 10px;
      }
      .add-button {
        background-color: #023f87;
        font-weight: bold;
        font-size: 14px;
        border-radius: 3px;
        padding: 6px 8px;
        color: #fff;
        margin-bottom: 5px;
        &:hover {
          background-color: #363636;
        }
      }

      .add-button-close {
        font-size: 12px;
        &:hover {
          color: red;
        }
      }
    }
  }
`;

export default Layout;

import LazyLoad from 'react-lazyload';
import ContentLoader from 'src/components/content-loader';
import { APP_IMG } from 'src/constants';

const Image = ({ title, image, ignoreLazy, horizontal = false, exLazy = '', external = false, type }) => {
  // console.log(image);
  let elem = (
    <div className='resolution-image'>
      <img src={`${APP_IMG}/default.jpg`} alt={title || 'image'} loading='lazy' width='100%' height='100%' />
    </div>
  );
  if (image) {
    let imgUrl = image;
    if (!external) {
      imgUrl = `${APP_IMG}${image}`;
    }
    // const imgUrl = image;
    switch (type) {
      case 'FEATURE_IMAGE':
        return (elem = (
          // <LazyLoad offset={100}>
          <picture className='resolution-image'>
            {/* <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/MD`} media={'(max-width: 767px)'} type='image/jpeg' /> */}
            <source srcSet={`${imgUrl}?x-image-process=style/LG-webp`} type='image/webp' />
            <img src={`${imgUrl}?x-image-process=style/LG`} alt={title} loading='lazy' className={`${exLazy}`} width='100%' height='100%' />
          </picture>
          // </LazyLoad>
        ));
      case 'COVER_IMAGE':
        return (elem = (
          // <LazyLoad offset={100}>
          <picture className='resolution-image'>
            {/* <source srcSet={`${imgUrl}?x-image-process=style/LG-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/LG`} media={'(max-width: 767px)'} type='image/jpeg' /> */}
            <source srcSet={`${imgUrl}?x-image-process=style/XL-webp`} type='image/webp' />
            <img src={`${imgUrl}?x-image-process=style/XL`} alt={title} loading='lazy' className={`${exLazy}`} width='100%' height='100%' />
          </picture>
          // </LazyLoad>
        ));
      case 'CARD_LARGE':
        return (elem = (
          // <LazyLoad offset={100}>
          <picture className='resolution-image'>
            <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} media={'(max-width: 767px)'} type='image/webp' />
            <source srcSet={`${imgUrl}?x-image-process=style/MD`} media={'(max-width: 767px)'} type='image/jpeg' />
            <source srcSet={`${imgUrl}?x-image-process=style/LG-webp`} type='image/webp' />
            <img src={`${imgUrl}?x-image-process=style/LG`} alt={title} loading='lazy' className={`${exLazy}`} width='100%' height='100%' />
          </picture>
          // </LazyLoad>
        ));
      case 'CARD_MEDIUM':
        return (elem = (
          // <LazyLoad offset={100}>
          <picture className='resolution-image'>
            <source srcSet={`${imgUrl}?x-image-process=style/XS-webp`} media={'(max-width: 767px)'} type='image/webp' />
            <source srcSet={`${imgUrl}?x-image-process=style/XS`} media={'(max-width: 767px)'} type='image/jpeg' />
            <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} type='image/webp' />
            <img src={`${imgUrl}?x-image-process=style/MD`} alt={title} loading='lazy' className={`${exLazy}`} width='100%' height='100%' />
          </picture>
          // </LazyLoad>
        ));
      case 'CARD_SMALL':
        return (elem = (
          // <LazyLoad offset={100}>
          <picture className='resolution-image'>
            <source srcSet={`${imgUrl}?x-image-process=style/SS-webp`} media={'(max-width: 767px)'} type='image/webp' />
            <source srcSet={`${imgUrl}?x-image-process=style/SS`} media={'(max-width: 767px)'} type='image/jpeg' />
            <source srcSet={`${imgUrl}?x-image-process=style/XS-webp`} type='image/webp' />
            <img src={`${imgUrl}?x-image-process=style/XS`} alt={title} loading='lazy' className={`${exLazy}`} width='100%' height='100%' />
          </picture>
          // </LazyLoad>
        ));
      default:
        return (elem = (
          <>
            {/* <LazyLoad offset={100}> */}
            <picture className='resolution-image'>
              <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} media={'(max-width: 768px)'} type='image/webp' />
              <source srcSet={`${imgUrl}?x-image-process=style/MD`} media={'(max-width: 768px)'} type='image/jpeg' />
              <source srcSet={`${imgUrl}?x-image-process=style/LG-webp`} media={'(max-width: 1440px)'} type='image/webp' />
              <source srcSet={`${imgUrl}?x-image-process=style/LG`} media={'(max-width: 1440px)'} type='image/jpeg' />
              <img src={`${imgUrl}?x-image-process=style/LG`} alt={title} loading='lazy' className={`${exLazy}`} width='100%' height='100%' />
            </picture>
            {/* </LazyLoad> */}
          </>
        ));
    }
  }

  if (ignoreLazy) return elem;
  return <LazyLoad placeholder={<ContentLoader hasText={false} horizontal={horizontal} />}>{elem}</LazyLoad>;
};

export default Image;

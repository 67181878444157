import { useEffect, useState } from 'react';
import numeral from 'numeral';
import { AiOutlineClockCircle, AiOutlineEye } from 'react-icons/ai';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import Image from 'src/components/image';

import { incrementCountview } from 'src/utils/increment-countview';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { RiPlayCircleLine } from 'react-icons/ri';
import { NEXT_PUBLIC_DEFAULT_COUNT_VIEW } from 'src/constants';
dayjs.extend(buddhistEra);

const CardV = ({ data, path, description = true, light = false, isVideo = false, badge = false, withTarget = false, eventDataLayer, heading, showPageviews = true, withBG = false, ecoCarosel = '', showFooter = true, showReadmore = false, external = false }) => {
  const [countView, setCountView] = useState(0);

  useEffect(() => {
    incrementCountview(data)
      .then(item => setCountView(item))
      .catch(() => setCountView(0));
  }, []);

  const categoryNameTH = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;

  const dateUpdate = dayjs(data?.published_at || data?.dateDefault)
    .locale('th')
    .format('DD MMM BBBB | H:mm');
  return (
    <a
      href={path}
      //! DATALAYER
      onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
      target={withTarget ? '_blank' : '_self'}
      rel={`${withTarget ? 'noopener noreferrer nofollow' : ''}`}
      className='card-wrapper'
    >
      <div className={`card-v ${ecoCarosel && ecoCarosel} ${withBG ? '--bg-light' : ''}`}>
        <div className={`card-v-thumb ${ecoCarosel && 'card-v-thumb-eco'}`}>
          <Image external={external} image={data?.image} title={data?.title} type='CARD_MEDIUM' />
          {
                isVideo && (
                  <div className='icon'>
                    <RiPlayCircleLine />
                  </div>
                )
              }
        </div>
        {badge && <span className='badge'>{categoryNameTH}</span>}
        <div className={`card-v-content ${ecoCarosel && 'card-v-content-eco'} ${light ? '--light' : ''}`}>
          <h3 className={`card-v-content-title ${description ? '--large-md' : ''} text-excerpt-2`}>{data?.title}</h3>
          {showFooter && (
            <div className={`card-v-content-meta ${description ? '--large-md' : ''}`}>
              <AiOutlineClockCircle />
              <span className='date'>{dateUpdate}</span>
              {showPageviews && countView > 0 && countView > Number(NEXT_PUBLIC_DEFAULT_COUNT_VIEW) && (
                <span className='pageview'>
                  <AiOutlineEye />
                  {countView > 999 ? numeral(countView).format('0.0a') : countView}
                </span>
              )}
            </div>
          )}
          {description && <p className='text-excerpt-3'>{data?.blurb}</p>}
          {
                showReadmore && (
                  <div className='read-more'>
                    <span>อ่านต่อ...</span>
                  </div>
                )
              }
        </div>
      </div>
    </a>
  );
};

export default CardV;

import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import numeral from 'numeral';

dayjs.extend(relativeTime);

export const timestamp = () => {
  return dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss');
};

export const splitFootballScore = text => {
  const textArr = text.split('-');
  const left = textArr[0].replaceAll(' ', '');
  const right = textArr[1].replaceAll(' ', '');
  return { left: left, right: right };
};

export function rewriteTxt(_txt) {
  if (_txt?.indexOf('.') !== -1) return 'latest-news';
  switch (_txt) {
    case 'rss':
      return 'latest-news';
    default:
      return _txt;
  }
}

export const convertArrPathLottery = _obj => {
  if (!Array.isArray(_obj.lottos) || _obj.lottos?.length === 0) return null;
  const feild = Object.keys(_obj)[0];
  const itemData = `?${feild}[]=${_obj.lottos.join(`&${feild}[]=`)}`;
  return itemData;
};

export const htmlEntities = str => {
  return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');
};

export const timeAgo = _date => {
  if (!_date) return null;
  const dateNow = dayjs(_date);
  const diffStr = dateNow.from(new Date());
  return diffStr;
};

export const escapeChar = str => {
  if (typeof str !== 'string') return null;
  return str.replace(/[\\]/g, '').replace(/["]/g, '\\$&');
};

export const escapeCharTitle = str => {
  return str.replace(/["]/g, '');
};
export function dropSomeField(obj, fieldArray) {
  const object = { ...obj };
  for (let index = 0; index < fieldArray.length; index++) {
    delete object[fieldArray[index]];
  }
  return object;
}

//! ใช้สำหรับเช็คอาร์เรย์ที่ต้องการให้แสดงถ้ามีมากกว่า 1 ตัว
// TODO : check array is empty
export function arrayEmpty(_array) {
  // console.log(_array.length);
  return Array.isArray(_array) && _array.length < 1;
}

export function convertBadge(data, format) {
  if (format) return `${data?.section?.th}/${data?.subCategory?.th}`;
  return data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
}
// ----------------- Convert Script to Tag HEAD ---------------------
// TODO : convert script ads from api
export const convertScriptToHead = _dataAds => {
  const wrapperDiv = document.createElement('div');
  const scriptElement = document.createElement('script');
  wrapperDiv.innerHTML = _dataAds;
  for (let i = 0; i < wrapperDiv.firstElementChild?.attributes.length; i++) {
    const attr = wrapperDiv.firstElementChild?.attributes[i];
    scriptElement.setAttribute(attr.name, attr.value);
    // console.log('attr', attr);
  }
  document.head.appendChild(scriptElement);
};

// TODO Wait API
export function delay(_ms) {
  return new Promise(resolve => {
    setTimeout(resolve, _ms);
  });
}

export const convertObjPath = _obj => {
  if (!_obj || Object.keys(_obj).length < 1) return '';
  const entries = Object.entries(_obj);
  const itemData = encodeURI(`?${entries.join('&').split(',').join('=')}`);
  return itemData;
};
export const convertObjPathNoneEncode = _obj => {
  if (!_obj || Object.keys(_obj).length < 1) return '';
  const entries = Object.entries(_obj);
  const itemData = (`?${entries.join('&').split(',').join('=')}`);
  return itemData;
};

export const convertArrPath = (_obj, field) => {
  let result = '';
  if (!_.isArray(_obj)) return null;

  if (_obj?.length > 0) {
    result = `?${field}[]=${_obj.join(`&${field}[]=`)}`;
  } else {
    result = `?${field}=${_obj[0]}`;
  }
  return encodeURI(result);
};

// useDateUpdate()
export function useDateUpdate(_date, _showTime) {
  if (!_date) return null;
  const _format = _showTime === true ? 'DD MMM YYYY |  HH:mm น.' : 'DD MMM YYYY';
  return dayjs(_date).locale('th').add(543, 'year').format(_format);
}
export function useTimeUpdate(_date) {
  if (!_date) return null;
  const _format = 'เวลา HH:mm น.';
  return dayjs(_date).locale('th').add(543, 'year').format(_format);
}

export function numberFormat(_score) {
  const result = _score > 0 ? numeral(+_score).format('0,0') : _score;
  return result;
}

export const numeralFormat = countView => {
  if (countView === 0) return null;
  return countView > 999 ? numeral(countView).format('0.0a') : countView;
};

export function htmlToPlainText(html) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  return dom.body.textContent || '';
}

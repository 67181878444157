import { useEffect, useState } from 'react';
import ContentLoader from 'src/components/content-loader';
import CardV from 'src/components/cards/card-v';
import { API_URI, APP_IMG } from 'src/constants';
import Layout from 'src/components/layouts';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { useDesktop } from 'src/utils/device';
import _ from 'lodash';
import parse from 'html-react-parser';

const mockArray = [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }, { id: '6' }];

const PageError = ({ sessionSevId, dataAdsHeader }) => {
  const [dataLatestNews, setDataLatestNews] = useState(null);

  useEffect(async () => {
    const res = await fetch(`${API_URI}/api/v1.0/categories/latest-news-by-one`);
    const resJson = await res.json();
    if ('msg' in resJson) {
      await setDataLatestNews(null);
    } else {
      await setDataLatestNews(resJson);
    }
  }, []);

  // console.log('==========> SOMETHING WRONG', statusCode);
  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo noindex={false} nofollow={false} />
      <Head>
        {/* ----------------------------- ScriptBrowser ------------------------------------ */}
        {!useDesktop() && !_.isEmpty(dataAdsHeader) ? parse(`${dataAdsHeader?.header_mobile}`) : ''}
        {useDesktop() && !_.isEmpty(dataAdsHeader) ? parse(`${dataAdsHeader?.header_desktop}`) : ''}
        <script async type='text/javascript' src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: 'var googletag = googletag || {}; window.googletag.cmd = googletag.cmd || [];'
          }}
        />
        {/* ------------------------- ADS : INTERSTITIALS ------------------------- */}
        {/* <script
          async
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
            window.googletag = window.googletag || {cmd: []};
            // GPT ad slots
            var interstitialSlot, staticSlot;

            googletag.cmd.push(function() {
                // Define a web interstitial ad slot.
                interstitialSlot = googletag.defineOutOfPageSlot(
                    '231599934,4696586/bangkokbiznews/interstital-ads/interstital-ads',
                    googletag.enums.OutOfPageFormat.INTERSTITIAL);

                // Slot returns null if the page or device does not support interstitials.
                if (interstitialSlot) {
                    interstitialSlot.addService(googletag.pubads());
                    document.getElementById('status').innerText = 'Interstitial is loading...';

                    // Add event listener to enable navigation once the interstitial loads.
                    // If this event doesn't fire, try clearing local storage and refreshing
                    // the page.
                    googletag.pubads().addEventListener('slotOnload', function(event) {

                        if (interstitialSlot === event.slot) {
                            document.getElementById('link').style.display = 'block';
                            document.getElementById('status').innerText = 'Interstitial is loaded.';
                        }
                    });
                }

                // Define static ad slots.
                staticSlot = googletag.defineSlot(
                        '231599934,4696586/bangkokbiznews/interstital-ads/interstital-ads', [1, 1],
                        'static-ad-1')
                    .addService(googletag.pubads());

                // Enable SRA and services.
                googletag.pubads().enableSingleRequest();
                googletag.enableServices();
            });`
          }}
        /> */}
      </Head>
      <Layout sessionSevId={sessionSevId}>
        <div className='page-404'>
          <section className='container'>
            <div className='error-box text-center'>
              <img src={`${APP_IMG}/no-article.png`} alt='no-article' width='100%' />
              <div>
                <a href='/' className='btn-error'>
                  กลับสู่หน้าหลัก
                </a>
              </div>
            </div>
            <div className='row error-news'>
              {dataLatestNews !== null ? (
                <>
                  {dataLatestNews?.data.slice(0, 6).map(item => (
                    <div key={item.id} className='col-6 col-md-4'>
                      <CardV data={item} description={false} path={item.link} nonePadding={true} isSmall={true} cardV={false} badge={true} withTarget={true} />
                    </div>
                  ))}
                </>
              ) : (
                mockArray.map(item => (
                  <div key={item.id} className='col-6 col-md-4'>
                    <ContentLoader />
                  </div>
                ))
              )}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default PageError;

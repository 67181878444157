const ContentLoader = ({ hasImage = true, hasText = true, horizontal = false }) => {
  return (
    <>
      {!horizontal ? (
        <div className='content-loading'>
          {hasImage && <div className='content-loading-image' />}
          {hasText && (
            <>
              <div className='content-loading-text' />
              <div className='content-loading-text --half' />
            </>
          )}
        </div>
      ) : (
        <div className='content-loading'>
          <div className='content-loading-image --horizontal' />
        </div>
      )}
    </>
  );
};

export default ContentLoader;
